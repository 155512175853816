



















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsSenderEmailCard',
    components: {
        VsListingCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private sender!: any

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private hideActions!: boolean

    get dkimEnabled () {
        return this.sender.dkim?.data.enabled
    }

    get badgeStatusAspect () {
        if (!this.sender?.status) return ''
        switch (this.sender?.status || null) {
            case 'Disabled':
                return 'alert'
            case 'Enabled':
                return 'success'
        }
    }

    get badgeStatusIcon () {
        if (!this.sender?.status) return ''
        switch (this.sender?.status || null) {
            case 'Disabled':
                return 'error'
            case 'Enabled':
                return 'double-check'
        }
    }
}
